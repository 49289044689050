import censureContent from '@gotombola/censure';

export type person_identity = {
    lastName?: string;
    firstName?: string;
    email?: string;
    phone?: string;
};

const formatters = {
    raw: (x: person_identity) => {
        if (!!x?.lastName || !!x?.firstName)
            return `${x?.lastName || ''}${!!x?.lastName && !!x?.firstName ? ' ' : ''}${x?.firstName || ''}`;
        return censureContent(x?.email || x?.phone || '', x?.email ? 'email' : 'phone', true);
    },
    obfuscated_lastname: (x: person_identity) => {
        if (!!x?.lastName || !!x?.firstName)
            return `${x?.lastName?.toUpperCase() || ''}${!!x?.lastName && !!x?.firstName ? ' ' : ''}${
                x?.firstName?.slice(0, 1) || ''
            }.`;
        return censureContent(x?.email || x?.phone || '', x?.email ? 'email' : 'phone', true);
    },
    obfuscated_firstname: (x: person_identity) => {
        if (!!x?.lastName || !!x?.firstName)
            return `${x?.firstName || ''}${!!x?.lastName && !!x?.firstName ? ' ' : ''}${
                x?.lastName?.toUpperCase()?.slice(0, 1) || ''
            }.`;
        return censureContent(x?.email || x?.phone || '', x?.email ? 'email' : 'phone', true);
    },
};

const defaultFormatter = 'raw';
export function formatIdentity(x: person_identity, format = 'default') {
    const formatter = formatters[format || ''] || formatters[defaultFormatter];

    return formatter(x);
}

export default formatIdentity;
